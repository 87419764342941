<template>
  <div class="serviceSupCss">
    <Navbar :pageName="pageName" />
    <div class="titleContentCss"><div class="titleCss">{{pageName}}</div></div>
  </div>
</template>

<script>
import Navbar from '@/views/home/components/Navbar.vue'

export default {
  name: 'newsCent',
  components: {
    Navbar
  },
  data() {
    return {
      pageName: ''
    }
  },
  mounted() {
    this.pageName = this.$route.query.pageName
  }
}
</script>

<style lang="stylus" scoped>
titleWidth = 59.8rem

.serviceSupCss {
  padding-bottom 4rem
  width 99.8rem
  background-color #333439
  font-size 0.8rem
  color white
  .titleContentCss {
    padding 2rem 20rem 0 20rem
    .titleCss {
      width titleWidth
      font-size 2rem
      height 4rem
      line-height 4rem
      border-bottom 1px solid white
    }
  }
}
</style>
